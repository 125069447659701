import { MDialog, MDialogProps } from '@mprise/react-ui'
import { useFormikContext } from 'formik'
import React, { useCallback } from 'react'

export const FormikDialog = (props: Omit<MDialogProps, 'onSubmit' | 'onReset'>) => {
  const formik = useFormikContext()
  const handleSubmit = useCallback<React.FormEventHandler>(
    e => {
      e.preventDefault()
      e.stopPropagation()
      formik.submitForm()
    },
    [formik.submitForm],
  )
  const handleReset = useCallback<React.FormEventHandler>(
    e => {
      e.preventDefault()
      e.stopPropagation()
      formik.resetForm()
    },
    [formik.resetForm],
  )
  return <MDialog {...props} onSubmit={handleSubmit} onReset={handleReset} />
}
