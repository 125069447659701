import { LocalDate, LocalDateTime } from '@js-joda/core'
import { MBlock, MFieldDate, MFieldInput, MText, MTextColor, ValidationIssues } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useHistory } from '../../shared/use-history'
import * as yup from 'yup'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { LoadingSwitchPanel } from '../../shared/loading-switch-panel'
import { toISOString } from '../../shared/date-time'
import { AutocompleteField, CheckboxField } from '../../shared/material-ui-formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MutationErrorMessage, QueryErrorMessage } from '../../shared/react-apollo'
import { FormikDialog } from '../../shared/react-formik-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { TrackingStatus } from '../../lib/enums'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { GET_ACTIVITIES } from '../../gql/activities'
import {
  GET_TRID_STATUS,
  GET_TRID_STATUSES,
  CREATE_TRID_STATUS,
  UPDATE_TRID_STATUS,
  DELETE_TRID_STATUS,
} from '../../gql/tracking-status'
import { Maybe } from '../../shared/typescript'

export interface WorkItemTemplateForm {
  id: string
  agriwareStatus: Maybe<{ id: TrackingStatus; code: string }>
  name: string
  companyId: string
  activeUntil: Maybe<LocalDate>
  isDefault: boolean
}

export const WarehouseStatusDialogRoute = () => {
  const params = useParams()
  return <WarehouseStatusDialog open statusId={params.trackingIdStatusId ?? null} />
}

export const WarehouseStatusDialog = ({ open, statusId }: { open: boolean; statusId: string | null }) => {
  const { t } = useTranslation()
  const h = useHistory()
  const companyId = useCurrentCompanyId()

  const [getStatus, { error: statusError, loading: statusLoading, data: statusData }] = useLazyQuery(GET_TRID_STATUS, {
    variables: { filter: { id: +statusId! } },
  })

  const [createStatus, { data: createData, error: createError }] = useMutation(CREATE_TRID_STATUS, {
    refetchQueries: [GET_TRID_STATUSES, 'customStatuses'],
  })
  const [updateStatus, { data: updateData, error: updateError }] = useMutation(UPDATE_TRID_STATUS, {
    refetchQueries: [GET_TRID_STATUSES, 'customStatuses'],
  })
  const [deleteStatus, { data: deleteData, error: deleteError }] = useMutation(DELETE_TRID_STATUS, {
    refetchQueries: [GET_TRID_STATUSES, 'customStatuses'],
  })

  useEffect(() => {
    if (statusId) {
      getStatus({
        variables: {
          filter: {
            id: +statusId,
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (createData) {
      h.push(`/tracking-id-status`, { replace: true })
    }
  }, [createData])

  useEffect(() => {
    if (updateData) {
      h.push(`/tracking-id-status`, { replace: true })
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData) {
      h.push(`/tracking-id-status`, { replace: true })
    }
  }, [deleteData])

  if (statusError) {
    console.error('statusError', statusError)
  }

  if (createError) {
    console.error('createError', createError)
  }

  if (updateError) {
    console.error('updateError', updateError)
  }

  if (deleteError) {
    console.error('deleteError', deleteError)
  }

  const [initialValues] = useLocalState<WorkItemTemplateForm>(
    () =>
      statusData
        ? {
            id: statusData.warehouseTrackingStatus.id,
            name: statusData.warehouseTrackingStatus.name ?? ``,
            agriwareStatus: statusData.warehouseTrackingStatus.agriwareStatus
              ? {
                  id: statusData.warehouseTrackingStatus.agriwareStatus,
                  code: t(`TrackingStatus.${statusData.warehouseTrackingStatus.agriwareStatus}`),
                }
              : null,
            activeUntil: statusData.warehouseTrackingStatus.activeUntil ?? null,
            isDefault: statusData.warehouseTrackingStatus.isDefault ?? false,
            companyId,
          }
        : { id: ``, name: ``, agriwareStatus: null, activeUntil: null, isDefault: false, companyId },
    [statusId, statusData, open, companyId],
  )

  const schema = WarehouseStatusDialog.useSchema()

  const [removing, setRemoving] = useLocalState(false, [open, statusId])

  const onSave = async (form: WorkItemTemplateForm) => {
    if (removing) {
      setRemoving(false)
      deleteStatus({
        variables: {
          id: +form.id,
        },
      })
    } else if (form.id) {
      updateStatus({
        variables: {
          id: +form.id,
          name: form.name,
          isDefault: form.isDefault,
          agriwareStatus: form.agriwareStatus?.id,
          activeUntil: form.activeUntil,
        },
      })
    } else {
      createStatus({
        variables: {
          name: form.name,
          isDefault: form.isDefault,
          agriwareStatus: form.agriwareStatus?.id ?? fail(`expects agriware status`),
          activeUntil: form.activeUntil,
          companyId: +form.companyId,
        },
      })
    }
  }

  const handleRemove = () => {
    setRemoving(true)
  }

  const handleClose = async () => {
    h.push(`/tracking-id-status`, { replace: true })
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={onSave}>
      <FormikDialog
        title={removing ? t(`TITLE_REMOVE_STATUS`) : statusData ? t(`TITLE_EDIT_STATUS`) : t(`TITLE_CREATE_STATUS`)}
        submit={
          removing ? t(`ACTION_CONFIRM_REMOVE`) : statusData ? t(`ACTION_SAVE_STATUS`) : t(`ACTION_CREATE_STATUS`)
        }
        removeChildren={statusData && !removing ? t(`ACTION_REMOVE`) : null}
        open={open}
        minWidth='sm'
        onClose={handleClose}
        onRemove={handleRemove}
      >
        <LoadingSwitchPanel loadingAnyway={statusLoading} query={null}>
          {/* <SavingSwitchPanel mutation={[createMutationState, updateMutationState]}> */}
          <ValidationIssues />
          {/* <QueryErrorMessage query={activitiesQuery} /> */}
          {/* <MutationErrorMessage mutation={[createMutationState, updateMutationState, removeMutationState]} /> */}
          {removing ? (
            <MBlock margin={5}>
              <MText block textVariant='content' textColor={MTextColor.dark}>
                Are you sure you would like to remove {initialValues.name}?
              </MText>
            </MBlock>
          ) : (
            <WarehouseStatusDialogForm />
          )}
          {/* </SavingSwitchPanel> */}
        </LoadingSwitchPanel>
      </FormikDialog>
    </Formik>
  )
}

WarehouseStatusDialog.useSchema = () => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      yup.object({
        id: yup.string().nullable().optional(),
        agriwareStatus: yup.object().label(t(`Tracking ID Status Category`)).nullable().required(),
        name: yup.string().label(t(`Name`)).required(),
        activeUntil: yup.string().label(t(`Active Until`)).nullable().optional(),
        isDefault: yup.boolean().label(t(`Is Default`)).nullable().required(),
      }),
    [],
  )
}

const WarehouseStatusDialogForm = () => {
  const { t } = useTranslation()
  const options = Object.values(TrackingStatus).map(x => ({
    id: x,
    code: t(`TrackingStatus.${x}`),
  }))

  return (
    <>
      <Field component={MFieldConnector} name='name' label={t(`Custom Status Name`)}>
        <MFieldInput />
      </Field>
      <Field
        component={AutocompleteField}
        name='agriwareStatus'
        label={t(`Tracking ID Status Category`)}
        options={options}
      />
      <Field component={CheckboxField} name='isDefault' description='Apply this as default custom status' />
      <Field component={MFieldConnector} name='activeUntil' label={t(`Valid Period`)}>
        <MFieldDate label={t(`Active until`)} />
      </Field>
    </>
  )
}
