import { Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material'
import { mdiCheck, mdiClose, mdiRestore } from '@mdi/js'
import { Icon } from '@mdi/react'
import {
  MColor,
  MDivider,
  MFlex,
  MFlexBlock,
  MFlexItem,
  MJoinChildren,
  MText,
  MTextColor,
  useMField,
} from '@mprise/react-ui'
import { Field, Formik, FormikProps, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikDialog } from '../../shared/dialog/formik-dialog'
import { FieldArraySwitch } from '../../shared/field-switch'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { TagPriority } from '../../shared/tag-priority'
import { defined, unique } from '../../shared/typescript'
import { ValidationIssues } from '../../shared/validation-issues'
import { yupEnumArray, yupObject } from '../../shared/yup-common-types'
import { AvailableResultTypes } from './restrictions'
import { WorkItemType, WorkPriority, WorkResultType } from '../../lib/enums'

export interface FilterValues {
  workItemTypes: Array<WorkItemType>
  priority: WorkPriority[]
  workResultTypes: Array<WorkResultType>
}

export const FilterDialog = ({
  open,
  initialValues,
  title = 'Filter',
  onSave,
  onClose,
}: {
  open: boolean
  initialValues: FilterValues
  title?: string
  onSave: (form: FilterValues) => void
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const schema = FilterDialog.useSchema()
  const formInstance = React.useRef<FormikProps<FilterValues>>(null)
  const handleCancel = () => {
    onClose()
  }
  const handleSubmit = (form: FilterValues) => {
    onSave(form)
  }

  const priorities = [WorkPriority.None, WorkPriority.Low, WorkPriority.Medium, WorkPriority.High]
  const workItemTypes = unique(Object.keys(AvailableResultTypes)).sort() as WorkItemType[]
  const workResultTypes = unique(Object.values(AvailableResultTypes).flat().filter(defined)).sort()
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      innerRef={formInstance}
    >
      <FormikDialog
        open={open}
        onClose={handleCancel}
        title={title}
        children={
          <>
            <ValidationIssues />
            <MJoinChildren divider={MDivider}>
              <Field component={MFieldConnector} name='workItemTypes' label={t(`Work Item Types`)}>
                <FormGroup>
                  {workItemTypes.map(x => (
                    <FieldArraySwitch key={x} value={x} label={t(`WorkItemType.${x}`)} />
                  ))}
                </FormGroup>
              </Field>
              <Field component={MFieldConnector} name='priority' label={t(`Priority`)} scrollableX>
                <MFlex wrap='nowrap' gap={2}>
                  {priorities.map(priority => (
                    <MFlexItem shrink={0} key={priority}>
                      <CheckBoxItem name={priority} value={priority}>
                        <TagPriority priority={priority} />
                      </CheckBoxItem>
                    </MFlexItem>
                  ))}
                </MFlex>
              </Field>
              <Field component={MFieldConnector} name='workResultTypes' label={t(`Task Results`)}>
                <FormGroup>
                  {workResultTypes.map(x => (
                    <FieldArraySwitch key={x} value={x} label={t(`WorkResultType.${x}`)} />
                  ))}
                </FormGroup>
              </Field>
            </MJoinChildren>
          </>
        }
        footer={
          <MFlexBlock alignItems='center' bgColor={MColor.paper}>
            <MFlexItem grow={1} basis='50%'>
              <MText block textColor={MTextColor.header}>
                {t(`Reset Filter`)}
              </MText>
            </MFlexItem>
            <MFlexItem>
              <ResetFormButton originalValues={FilterDialog.emptyForm} />
            </MFlexItem>
            <MFlexItem grow={1} basis='50%' />
          </MFlexBlock>
        }
      />
    </Formik>
  )
}

FilterDialog.emptyForm = {
  workItemTypes: [],
  priority: [WorkPriority.None, WorkPriority.Low, WorkPriority.Medium, WorkPriority.High],
  workResultTypes: [],
} as Readonly<FilterValues>

FilterDialog.useSchema = () => {
  const { t } = useTranslation()
  const [schema] = useState(() =>
    yupObject<FilterValues>({
      workItemTypes: yupEnumArray(WorkItemType).label(t(`Work Template Types`)),
      priority: yupEnumArray(WorkPriority).label(t(`Priority`)),
      workResultTypes: yupEnumArray(WorkResultType).label(t(`Types`)),
    }),
  )

  return schema
}

FilterDialog.useDialogState = () => {
  const [state, setState] = useState({
    open: false,
    values: FilterDialog.emptyForm,
  })
  return {
    open: state.open,
    values: state.values,
    handleSave(values: FilterValues) {
      setState(x => ({
        ...x,
        open: false,
        values,
      }))
    },
    handleOpen() {
      setState(x => ({
        ...x,
        open: true,
      }))
    },
    handleClose() {
      setState(x => ({
        ...x,
        open: false,
      }))
    },
  }
}

const CheckBoxItem = ({ name, value, children }: { name: string; value: any; children?: React.ReactNode }) => {
  const f = useMField()
  const checked = Array.isArray(f.value) && f.value.includes(value)
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    f.onChange?.(toggle(f.value, value, e.target.checked))
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<Icon size={1} path={mdiClose} color={MTextColor.dark} />}
          checkedIcon={<Icon size={1} path={mdiCheck} />}
          color='primary'
          name={name}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={
        <MText block textColor={MTextColor.dark}>
          {children}
        </MText>
      }
    />
  )
}

const toggle = <T extends unknown>(selected: T[], value: T, checked: boolean) => {
  const list = new Set(selected)
  if (checked) {
    list.add(value)
  } else {
    list.delete(value)
  }
  return Array.from(list)
}

const ResetFormButton = ({ originalValues }: { originalValues: any }) => {
  const form = useFormikContext()
  const handleReset = () => {
    form.setValues(originalValues)
  }
  const unchanged = JSON.stringify(form.values) === JSON.stringify(originalValues)
  return (
    <IconButton color='primary' disabled={unchanged} onClick={handleReset}>
      <Icon size={1.5} path={mdiRestore} />
    </IconButton>
  )
}
