import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup, IconButton } from '@mui/material'
import { mdiRestore } from '@mdi/js'
import { Icon } from '@mdi/react'
import { MColor, MDivider, MFlexBlock, MFlexItem, MJoinChildren, MText, MTextColor } from '@mprise/react-ui'
import { Field, Formik, FormikProps, useFormikContext } from 'formik'
import { FormikDialog } from '../../shared/dialog/formik-dialog'
import { FieldArraySwitch } from '../../shared/field-switch'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { ValidationIssues } from '../../shared/validation-issues'
import { yupEnumArray, yupObject } from '../../shared/yup-common-types'
import { WorkItemType, WorkResultType } from '../../lib/enums'

export interface GHFilterValues {
  workItemTypes: Array<WorkItemType>
  workResultTypes: Array<WorkResultType>
}

export const GHFilterDialog = ({
  open,
  initialValues,
  title = 'Filter',
  onSave,
  onClose,
}: {
  open: boolean
  initialValues: GHFilterValues
  title?: string
  onSave: (form: GHFilterValues) => void
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const schema = GHFilterDialog.useSchema()
  const formInstance = React.useRef<FormikProps<GHFilterValues>>(null)
  const handleCancel = () => {
    onClose()
  }
  const handleSubmit = (form: GHFilterValues) => {
    onSave(form)
  }

  const workItemTypes = [WorkItemType.JobPickOrder, WorkItemType.JobWorkOrder]
  const workResultTypes = [
    WorkResultType.ItemConsumption,
    WorkResultType.JobInventoryPutaway,
    WorkResultType.ItemOutput,
  ]

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      innerRef={formInstance}
    >
      <FormikDialog
        open={open}
        onClose={handleCancel}
        title={title}
        children={
          <>
            <ValidationIssues />
            <MJoinChildren divider={MDivider}>
              <Field component={MFieldConnector} name='workItemTypes' label={t(`Work Item Types`)}>
                <FormGroup>
                  {workItemTypes.map(x => (
                    <FieldArraySwitch key={x} value={x} label={t(`WorkItemType.${x}`)} />
                  ))}
                </FormGroup>
              </Field>
              <Field component={MFieldConnector} name='workResultTypes' label={t(`Task Results`)}>
                <FormGroup>
                  {workResultTypes.map(x => (
                    <FieldArraySwitch key={x} value={x} label={t(`WorkResultType.${x}`)} />
                  ))}
                </FormGroup>
              </Field>
            </MJoinChildren>
          </>
        }
        footer={
          <MFlexBlock alignItems='center' bgColor={MColor.paper}>
            <MFlexItem grow={1} basis='50%'>
              <MText block textColor={MTextColor.header}>
                {t(`Reset Filter`)}
              </MText>
            </MFlexItem>
            <MFlexItem>
              <ResetFormButton originalValues={GHFilterDialog.emptyForm} />
            </MFlexItem>
            <MFlexItem grow={1} basis='50%' />
          </MFlexBlock>
        }
      />
    </Formik>
  )
}

GHFilterDialog.emptyForm = {
  workItemTypes: [],
  workResultTypes: [],
} as Readonly<GHFilterValues>

GHFilterDialog.useSchema = () => {
  const { t } = useTranslation()
  const [schema] = useState(() =>
    yupObject<GHFilterValues>({
      workItemTypes: yupEnumArray(WorkItemType).label(t(`Work Template Types`)),
      workResultTypes: yupEnumArray(WorkResultType).label(t(`Types`)),
    }),
  )

  return schema
}

GHFilterDialog.useDialogState = () => {
  const [state, setState] = useState({
    open: false,
    values: GHFilterDialog.emptyForm,
  })
  return {
    open: state.open,
    values: state.values,
    handleSave(values: GHFilterValues) {
      setState(x => ({
        ...x,
        open: false,
        values,
      }))
    },
    handleOpen() {
      setState(x => ({
        ...x,
        open: true,
      }))
    },
    handleClose() {
      setState(x => ({
        ...x,
        open: false,
      }))
    },
  }
}

const ResetFormButton = ({ originalValues }: { originalValues: any }) => {
  const form = useFormikContext()
  const handleReset = () => {
    form.setValues(originalValues)
  }
  const unchanged = JSON.stringify(form.values) === JSON.stringify(originalValues)
  return (
    <IconButton color='primary' disabled={unchanged} onClick={handleReset}>
      <Icon size={1.5} path={mdiRestore} />
    </IconButton>
  )
}
