import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { MFieldConnector, MFieldDate, Yup, useMField } from '@mprise/react-ui'
import { Autocomplete, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { Field } from 'formik'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DELETE_USER, GET_LICENSE_USAGE, GET_TENANT_USERS, KNOWN_EMAILS, UPDATE_USER } from '../../gql/users'
import { useDebounceValue } from '../../shared/debounce-value'
import { useHistory } from '../../shared/use-history'
import { PatternDialog } from '../../shared/pattern-dialog'
import { Maybe } from '../../shared/typescript'
import { LocalDate } from '@js-joda/core'
import { FieldResource } from '../../shared/form/field-resource'
import { AssigneeValue } from '../../shared/dialog/select-assignee-dialog'
import { FieldLocationSet, SimpleLocation } from '../../shared/form/field-location-set-plain'
import { FieldActivitySet, SimpleActivity } from '../../shared/form/field-activity-set'
import { FieldRoleSet, SimpleRole } from '../../shared/form/field-role-set'
import { withFormikCompareFix } from '../../shared/formik'
import { useLocalState } from '../../shared/react-local-state'
import { DeleteRecordDialog } from '../../shared/dialog/delete-record-dialog'

interface UpsertUserFormValues {
  email: string
  roles: Maybe<SimpleRole[]>
  activeUntil: Maybe<LocalDate>
  resource: Maybe<AssigneeValue>
  locations: Maybe<SimpleLocation[]>
  activities: Maybe<SimpleActivity[]>
}

export const UserDialog = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = UserDialog.useSchema()

  const { userId } = useParams() as { userId: string }

  const [getUser, { data, loading }] = useLazyQuery(GET_TENANT_USERS, {
    variables: { filter: { id: +userId } },
  })

  const [upsertUser] = useMutation(UPDATE_USER, {
    refetchQueries: [GET_TENANT_USERS, GET_LICENSE_USAGE],
  })

  useEffect(() => {
    if (userId) {
      getUser({
        variables: {
          filter: {
            id: +userId,
          },
        },
      })
    }
  }, [getUser, userId])

  const [initialValues] = useLocalState<UpsertUserFormValues>(() => {
    const user = data?.users?.page?.[0]
    if (user) {
      return withFormikCompareFix({
        email: user.email,
        roles: user.roles,
        activeUntil: user.activeUntil,
        resource: user.resource,
        locations: user.locationPermissions,
        activities: user.activityPermissions,
      })
    } else {
      return withFormikCompareFix({
        email: '',
        roles: [],
        activeUntil: null,
        resource: null,
        locations: [],
        activities: [],
      })
    }
  }, [data])

  const handleSave = async (formValues: UpsertUserFormValues) => {
    const response = await upsertUser({
      variables: {
        input: {
          email: formValues.email,
          resourceId: +(formValues.resource?.id ?? 0),
          roleIds: formValues.roles?.map(x => x.id),
          activeUntil: formValues.activeUntil,
          locationIds: formValues.locations?.map(x => x.id),
          activityIds: formValues.activities?.map(x => x.id),
        },
      },
    })

    const upsertedUserId = response.data?.updateUser
    if (upsertedUserId) {
      handleClose()
    }
  }

  const handleClose = () => {
    h.push('/users', { replace: true })
  }

  const [deleting, setDeleting] = useState<Maybe<{ id: number; name: string }>>(null)
  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const user = data?.users?.page?.[0]
    if (user) {
      setDeleting({ id: user.id, name: user.email })
    }
  }
  const deleteDialogClose = () => setDeleting(null)

  return (
    <>
      {deleting && (
        <DeleteRecordDialog
          record={deleting}
          recordType={t('User')}
          onClose={deleteDialogClose}
          editPopupClose={handleClose}
          deleteQuery={DELETE_USER}
          refetchQueries={[GET_TENANT_USERS]}
        />
      )}
      <PatternDialog<UpsertUserFormValues>
        title={userId ? t('Edit User') : t('New User')}
        submit={userId ? t('Edit User') : t('Create User')}
        initialValues={initialValues}
        onClose={handleClose}
        onSave={handleSave}
        onDelete={userId ? handleDelete : undefined}
        schema={schema}
        open={true}
        query={userId ? [{ loading }] : null}
      >
        <Box minWidth={400} padding={2}>
          <Field component={MFieldConnector} name='email' label={t('Email')}>
            <FieldUserEmail disabled={!!userId} />
          </Field>
          <Field component={MFieldConnector} name='resource' label={t('Resource')}>
            <FieldResource />
          </Field>
          <Field component={MFieldConnector} name='activeUntil' label={t('Active Until')}>
            <MFieldDate label='' slotProps={{ textField: { fullWidth: true, variant: 'standard' } }} />
          </Field>
          <Field component={MFieldConnector} name='roles' label={t('Roles')}>
            <FieldRoleSet />
          </Field>
          <Field component={MFieldConnector} name='locations' label={t('Locations')}>
            <FieldLocationSet />
          </Field>
          {/* <Field component={MFieldConnector} name='genuses' label={t('Genuses')}>
            <FieldGenusSet title={'Genuses'} />
          </Field>
          <Field component={MFieldConnector} name='jobTypes' label={t('Job Types')}>
            <FieldJobTypeSet title={'Job Types'} />
          </Field> */}
          <Field component={MFieldConnector} name='activities' label={t('Activities')}>
            <FieldActivitySet />
          </Field>
        </Box>
      </PatternDialog>
    </>
  )
}

UserDialog.useSchema = () => {
  const { t } = useTranslation()
  return Yup.object().shape({
    email: Yup.string().email().label(t('Email')).required(),
  })
}

const FieldUserEmail = ({ disabled }: { disabled: boolean }) => {
  const f = useMField()
  const [getKnownEmails, { data }] = useLazyQuery(KNOWN_EMAILS)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 300)

  useEffect(() => {
    if (debouncedSearch) {
      getKnownEmails({
        variables: {
          searchString: debouncedSearch,
        },
      })
    }
  }, [debouncedSearch, getKnownEmails])

  return (
    <Autocomplete
      options={data?.knownEmails ?? []}
      freeSolo
      autoSelect
      disabled={disabled}
      includeInputInList
      onInputChange={(event, text) => {
        if (event?.type === 'change') {
          setSearch(text)
        }
      }}
      getOptionLabel={option => option ?? '-'}
      value={f.value?.toString() ?? ''}
      onChange={(_, text) => {
        f.onChange?.(text)
      }}
      renderInput={params => <TextField {...params} variant='standard' autoFocus type='email' />}
    />
  )
}
