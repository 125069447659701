import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { MFieldConnector, MFieldInput, MText, Yup } from '@mprise/react-ui'
import { Box } from '@mui/system'
import { Field } from 'formik'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useHistory } from '../../shared/use-history'
import { PatternDialog } from '../../shared/pattern-dialog'
import { Maybe } from '../../shared/typescript'
import { FieldLocationSet, SimpleLocation } from '../../shared/form/field-location-set-plain'
import { FieldActivitySet, SimpleActivity } from '../../shared/form/field-activity-set'
import { withFormikCompareFix } from '../../shared/formik'
import { useLocalState } from '../../shared/react-local-state'
import { DELETE_ROLE, GET_CONFIGURABLE_PERMISSIONS, GET_TENANT_ROLES, UPDATE_ROLE } from '../../gql/roles'
import { FieldPermissionSubset } from '../../shared/form/field-permission-set'
import { DeleteRecordDialog } from '../../shared/dialog/delete-record-dialog'

interface UpsertRoleFormValues {
  name: string
  permissions: string[]
  locations: Maybe<SimpleLocation[]>
  activities: Maybe<SimpleActivity[]>
}

export const RoleDialog = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = RoleDialog.useSchema()

  const { roleId } = useParams() as { roleId: string }

  const [getRole, { data: roleData, loading: roleLoading }] = useLazyQuery(GET_TENANT_ROLES)

  const { data: permissionsData, loading: permissionsLoading } = useQuery(GET_CONFIGURABLE_PERMISSIONS)

  const [upsertRole] = useMutation(UPDATE_ROLE, {
    refetchQueries: [GET_TENANT_ROLES],
  })

  useEffect(() => {
    if (roleId) {
      getRole({
        variables: {
          filter: {
            id: roleId,
          },
        },
      })
    }
  }, [getRole, roleId])

  const [initialValues] = useLocalState<UpsertRoleFormValues>(() => {
    const role = roleData?.roles?.[0]
    if (role) {
      return withFormikCompareFix({
        name: role.name,
        permissions: role.permissions.map((x: any) => x.id),
        locations: role.locationPermissions,
        activities: role.activityPermissions,
      })
    } else {
      return withFormikCompareFix({
        name: '',
        permissions: [],
        locations: [],
        activities: [],
      })
    }
  }, [roleData])

  const handleSave = async (formValues: UpsertRoleFormValues) => {
    const response = await upsertRole({
      variables: {
        input: {
          ...(roleId && { id: roleId }),
          name: formValues.name,
          permissions: formValues.permissions,
          locationIds: formValues.locations?.map(x => x.id),
          activityIds: formValues.activities?.map(x => x.id),
        },
      },
    })

    const upsertedRoleId = response.data?.updateRole
    if (upsertedRoleId) {
      handleClose()
    }
  }

  const handleClose = () => {
    h.push('/roles', { replace: true })
  }

  const [deleting, setDeleting] = useState<Maybe<{ id: number; name: string }>>(null)
  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const role = roleData?.roles?.[0]
    if (role) {
      setDeleting(role)
    }
  }
  const deleteDialogClose = () => setDeleting(null)

  return (
    <>
      {deleting && (
        <DeleteRecordDialog
          record={deleting}
          recordType={t('Role')}
          onClose={deleteDialogClose}
          editPopupClose={handleClose}
          deleteQuery={DELETE_ROLE}
          refetchQueries={[GET_TENANT_ROLES]}
        />
      )}
      <PatternDialog<UpsertRoleFormValues>
        title={roleId ? t('Edit Role') : t('New Role')}
        submit={roleId ? t('Edit Role') : t('Create Role')}
        initialValues={initialValues}
        onClose={handleClose}
        onSave={handleSave}
        onDelete={roleId ? handleDelete : undefined}
        schema={schema}
        open={true}
        query={[{ loading: permissionsLoading && (roleId ? roleLoading : true) }]}
      >
        <Box minWidth={400} padding={2}>
          <Field component={MFieldConnector} name='name' label={t('Name')}>
            <MFieldInput />
          </Field>
          <br />
          <MText style={{ paddingLeft: '0.5rem' }} block textVariant='small bold'>
            {t('Access Permissions')}
          </MText>
          <Field component={MFieldConnector} name='permissions'>
            {permissionsData?.configurablePermissions.map((application: any) => (
              <FieldPermissionSubset
                key={application.name}
                label={application.name}
                permissionSubset={application.permissions}
              />
            ))}
          </Field>
          <br />
          <MText style={{ paddingLeft: '0.5rem', marginBottom: '0.5rem' }} block textVariant='small bold'>
            {t('Data Permissions')}
          </MText>
          <Field component={MFieldConnector} name='locations' label={t('Locations')}>
            <FieldLocationSet />
          </Field>
          {/* <Field component={MFieldConnector} name='genuses' label={t('Genuses')}>
            <FieldGenusSet title={'Genuses'} />
          </Field>
          <Field component={MFieldConnector} name='jobTypes' label={t('Job Types')}>
            <FieldJobTypeSet title={'Job Types'} />
          </Field> */}
          <Field component={MFieldConnector} name='activities' label={t('Activities')}>
            <FieldActivitySet />
          </Field>
        </Box>
      </PatternDialog>
    </>
  )
}

RoleDialog.useSchema = () => {
  const { t } = useTranslation()
  return Yup.object().shape({
    name: Yup.string().label(t('Name')).required(),
  })
}
