import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { mdiDotsVertical, mdiPlusCircle } from '@mdi/js'
import Icon from '@mdi/react'
import { MColor, useMField } from '@mprise/react-ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikCompareFix, withFormikCompareFix, withoutFormikCompareFix } from '../../shared/formik'
import { TaskDialog, TaskForm } from './task-dialog'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { WorkItemTemplateTaskOption, WorkItemType } from '../../lib/enums'

export const TaskListField = ({ templateType }: { templateType: WorkItemType }) => {
  const f = useMField()
  const unorderedTasks = f.value as TaskForm[]

  const { t } = useTranslation()

  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlert = (alertType: AlertType) => setAlert(alertType)
  const handleAlertClose = () => setAlert(null)

  const [menuIdx, setMenuIdx] = useState<number | null>(null)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setMenuIdx(idx)
    setMenuAnchor(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setMenuIdx(null)
    setMenuAnchor(null)
  }

  const [editTask, setEditTask] = useState<TaskForm & FormikCompareFix>(
    withFormikCompareFix({
      order: 0,
      name: ``,
      types: [],
      taskOptions: [],
      settingFinishOnFulfilled: null,
      settingItemConsumptionWarehouseStatus: null,
      settingItemOutputWarehouseStatus: null,
      settingPackInputNextWarehouseStatus: null,
      settingPackOutputNextWarehouseStatus: null,
      settingLoadInputNextWarehouseStatus: null,
      settingLoadOutputNextCarrierStatus: null,
      settingsResourceReport: null,
    }),
  )

  const orderedTasks = unorderedTasks.slice().sort((a, b) => a.order - b.order)
  const [taskIdx, setTaskIdx] = useState<number | null>(null)
  const addingTask = taskIdx === -1
  const handleOpenTask = (idx: number) => {
    setTaskIdx(idx)
    if (idx === -1) {
      setEditTask(
        withFormikCompareFix({
          name: ``,
          types: [],
          taskOptions: [],
          order: 0,
          settingFinishOnFulfilled: null,
          settingItemConsumptionWarehouseStatus: null,
          settingItemOutputWarehouseStatus: null,
          settingPackInputNextWarehouseStatus: null,
          settingPackOutputNextWarehouseStatus: null,
          settingLoadInputNextWarehouseStatus: null,
          settingLoadOutputNextCarrierStatus: null,
          settingsResourceReport: null,
        }),
      )
    } else {
      setEditTask(withFormikCompareFix(orderedTasks[idx]))
    }
  }
  const handleSaveTask = (valuesWithFix: TaskForm & FormikCompareFix) => {
    // Check taskOption (for JobPick template): BLOCK_CHANGING_OUTPUT_POSITION can only be selected
    // if PREFILL_DEFAULT_OUTPUT_POSITION is selected. Otherwise remove from taskOptions.
    if (
      valuesWithFix.taskOptions.includes(WorkItemTemplateTaskOption.PickBlockChangingOutputPosition) &&
      !valuesWithFix.taskOptions.includes(WorkItemTemplateTaskOption.PickPrefillDefaultOutputPosition)
    ) {
      valuesWithFix.taskOptions = valuesWithFix.taskOptions.filter(
        taskOption => taskOption !== WorkItemTemplateTaskOption.PickBlockChangingOutputPosition,
      )
    }

    const newOrEditedTask = withoutFormikCompareFix(valuesWithFix)
    const newTaskTypes = newOrEditedTask.types
    const alreadyUsedTaskTypes = orderedTasks.filter(task => task.order !== newOrEditedTask.order).flatMap(x => x.types)
    const hasDuplicateTaskTypes = alreadyUsedTaskTypes.some(x => newTaskTypes.includes(x))
    if (hasDuplicateTaskTypes) {
      handleAlert('duplicate')
      return
    }
    if (addingTask) {
      const newTaskList = [...orderedTasks, newOrEditedTask]
      const newTaskListReIndexed = newTaskList.map((task, index) => ({ ...task, order: index + 1 }))
      f.onChange?.(newTaskListReIndexed)
    } else {
      const newTaskList = orderedTasks.map((orig, index) => (index === taskIdx ? newOrEditedTask : orig))
      const newTaskListReIndexed = newTaskList.map((task, index) => ({ ...task, order: index + 1 }))
      f.onChange?.(newTaskListReIndexed)
    }
    setTaskIdx(null)
  }
  const handleCloseTask = () => setTaskIdx(null)

  const canMoveUp = menuIdx !== null && menuIdx > 0
  const canMoveDown = menuIdx !== null && menuIdx < orderedTasks.length - 1
  const handleClickMoveUp = () => {
    if (menuIdx !== null) {
      const task = orderedTasks[menuIdx]
      const tasks = orderedTasks.slice(0)
      tasks.splice(menuIdx, 1)
      tasks.splice(menuIdx - 1, 0, task)
      const ordered = tasks.map((task, index) => ({ ...task, order: index + 1 }))
      f.onChange?.(ordered)
    }
    handleCloseMenu()
  }
  const handleClickMoveDown = () => {
    if (menuIdx !== null) {
      const task = orderedTasks[menuIdx]
      const tasks = orderedTasks.slice(0)
      tasks.splice(menuIdx, 1)
      tasks.splice(menuIdx + 1, 0, task)
      const ordered = tasks.map((task, idx) => ({ ...task, order: idx + 1 }))
      f.onChange?.(ordered)
    }
    handleCloseMenu()
  }
  const handleClickDeleteTask = () => {
    const removed = orderedTasks.filter((_, idx) => menuIdx !== idx)
    const ordered = removed.map((task, idx) => ({ ...task, order: idx + 1 }))
    f.onChange?.(ordered)
    handleCloseMenu()
  }

  return (
    <>
      <Menu anchorEl={menuAnchor} keepMounted open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
        <MenuItem onClick={handleClickMoveUp} disabled={!canMoveUp}>
          Move Up
        </MenuItem>
        <MenuItem onClick={handleClickMoveDown} disabled={!canMoveDown}>
          Move Down
        </MenuItem>
        <MenuItem onClick={handleClickDeleteTask}>Delete Task</MenuItem>
      </Menu>
      <TaskDialog
        templateType={templateType}
        open={taskIdx !== null}
        initialValues={editTask}
        title={addingTask ? t(`Add Task`) : t(`Edit Task`)}
        label={addingTask ? t(`Add Task`) : t(`Edit Task`)}
        onClose={handleCloseTask}
        onSave={handleSaveTask}
        alert={alert}
        onAlertClose={handleAlertClose}
      />
      <ListWithDividers>
        {orderedTasks.map((task, idx) => (
          <ListItem key={idx} button onClick={() => handleOpenTask(idx)}>
            <ListItemText
              primary={`${idx + 1}. ${task.name}`}
              secondary={task.types.map(type => t(`WorkResultType.${type}`)).join(`, `)}
            />
            <ListItemSecondaryAction onClick={e => handleOpenMenu(e, idx)}>
              <IconButton>
                <Icon path={mdiDotsVertical} size={1} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem button style={{ color: MColor.primary }} onClick={() => handleOpenTask(-1)}>
          <ListItemIcon>
            <Icon path={mdiPlusCircle} size={1.8} color={MColor.primary} />
          </ListItemIcon>
          <ListItemText primary={t(`Add Task`)} />
        </ListItem>
      </ListWithDividers>
    </>
  )
}

const ListWithDividers = ({ children }: { children: React.ReactNode }) => {
  return (
    <List>
      <Divider />
      {React.Children.toArray(children).flatMap((item, index) => [item, <Divider key={'divider' + index} />])}
    </List>
  )
}
