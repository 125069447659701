import Collapse from '@mui/material/Collapse'
import { MColor, MFieldInput, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { AlertType } from '../../shared/alert-dialog'
import { SimpleLocation } from '../../shared/dialog/select-locations-dialog'
import { SimpleTeam } from '../../shared/dialog/select-teams-dialog'
import { FieldLocationSet } from '../../shared/form/field-location-set'
import { FieldRPGWorkItemType } from '../../shared/form/field-rpg-workitem-type-set'
import { FieldTeamSet } from '../../shared/form/field-team-set'
import { withFormikCompareFix } from '../../shared/formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MSection, MSections } from '../../shared/msection'
import { MutationErrorMessage } from '../../shared/react-apollo'
import { FormikDialog } from '../../shared/react-formik-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { ValidationIssues } from '../../shared/validation-issues'
import { yup, yupObject, yupObjectArray } from '../../shared/yup-common-types'
import { WorkItemType } from '../../lib/enums'
import { useMutation } from '@apollo/client'
import { GET_ALL_RPGS, CREATE_RPG } from '../../gql/rpgs'

const MAX_LENGTH_INPUT_FIELD = 50

interface createRPGInput {
  name: string
  companyId: string
  description: string
  teams: SimpleTeam[]
  locations: SimpleLocation[]
  types: string[]
}

export const ResourcePlanningGroupDialogCreate = ({
  open,
  onClose,
  handleAlert,
  rpgs,
}: {
  handleAlert: (alertType: AlertType) => void
  open: boolean
  onClose: () => any
  rpgs: any
}) => {
  const { t } = useTranslation()

  const companyId = useCurrentCompanyId()

  const [conflict, setConflict] = useState(false)
  const [conflicts, setConflicts] = useState([])

  const [initialValues] = useLocalState<createRPGInput>(
    () =>
      withFormikCompareFix({
        name: '',
        description: '',
        companyId,
        teams: [],
        locations: [],
        types: [],
      }),
    [companyId, open],
  )

  useEffect(() => {
    setConflict(false)
  }, [open])

  const [createRpg, { data: createData, error: createError }] = useMutation(CREATE_RPG, {
    refetchQueries: [GET_ALL_RPGS, 'resourcePlanningGroups'],
  })

  if (createError) {
    console.error('createError', createError)
  }

  useEffect(() => {
    if (createData) {
      // on created
      handleAlert('create')
      // on close
      onClose()
    }
  }, [createData])

  const schema = ResourcePlanningGroupDialogCreate.useSchema()

  // const { save, mutations } = ResourcePlanningGroupDialogCreate.useSave()

  const handleSubmit = async (form: createRPGInput) => {
    const conflicts: never[] = []
    const existingName = rpgs.find((r: any) => form.name === r.name)

    if (existingName) {
      conflicts.push(t('RPG_name_conflict'))
    }

    if (form.types.length === 0) {
      conflicts.push(t('Select one or multiple work item types'))
    }

    if (form.teams.length === 0) {
      conflicts.push(t('Select one or multiple teams'))
    }

    if (form.locations.length === 0) {
      conflicts.push(t('Select one or multiple locations'))
    }

    if (conflicts.length > 0) {
      setConflict(true)
      setConflicts(conflicts)
    } else {
      createRpg({
        variables: {
          input: {
            companyId: +form.companyId,
            name: form.name,
            description: form.description,
            teamIds: form.teams?.map(t => +t.id) ?? [],
            locationIds: form.locations && form.locations.length > 0 ? form.locations.map(l => +l.id) : [],
            workItemTypes: form.types && form.types.length > 0 ? (form.types as WorkItemType[]) : [],
          },
        },
      })
    }
  }

  // const errors = mutations.flatMap((x) => x.error?.graphQLErrors ?? [])
  // const has409 = errors.some((x) => x.message.match(/409/))

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <FormikDialog
        minWidth='md'
        open={open}
        title={t('DialogCreateRPGTitle')}
        submit={t('DialogCreateRPGTitle')}
        onClose={onClose}
      >
        <ValidationIssues />
        {conflict && <ResourcePlanningGroupDialogCreateConflict conflicts={conflicts} />}
        {/* {!has409 && <MutationErrorMessage mutation={mutations} />} */}
        <ResourcePlanningGroupDialogCreateForm />
      </FormikDialog>
    </Formik>
  )
}

const ResourcePlanningGroupDialogCreateConflict = ({ conflicts }: { conflicts: never[] }) => {
  const { t } = useTranslation()

  return (
    <Collapse in={true} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MText block textVariant='content bold'>
            {t('Conflict')}
          </MText>
          {conflicts.map((c: any) => (
            <MText block textVariant='small'>
              {c}
            </MText>
          ))}
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}

const ResourcePlanningGroupDialogCreateForm = () => {
  const { t } = useTranslation()

  return (
    <MSections>
      <MSection title={t(`Resource Planning Group`)}>
        <Field component={MFieldConnector} name='name' label={t(`Name`)}>
          <MFieldInput inputProps={{ maxLength: MAX_LENGTH_INPUT_FIELD }} />
        </Field>
        <Field component={MFieldConnector} name='description' label={t(`Description`)}>
          <MFieldInput inputProps={{ maxLength: MAX_LENGTH_INPUT_FIELD }} />
        </Field>
      </MSection>
      <MSection title={t(`Filters`)}>
        <Field component={MFieldConnector} name='types' label={t(`Work Item Types`)}>
          <FieldRPGWorkItemType title={t('Work Item Type')} />
        </Field>
        <Field component={MFieldConnector} name='teams' label={t(`Teams`)}>
          <FieldTeamSet title='Teams' />
        </Field>
        <Field component={MFieldConnector} name='locations' label={t(`Locations`)}>
          <FieldLocationSet title={t('Locations')} />
        </Field>
      </MSection>
    </MSections>
  )
}

ResourcePlanningGroupDialogCreate.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(() =>
    yupObject<createRPGInput>({
      companyId: yup.string().required(),
      name: yup.string().required().min(4).max(MAX_LENGTH_INPUT_FIELD).label(t(`Name`)) as any,
      description: yup.string().min(4).max(MAX_LENGTH_INPUT_FIELD).required().label(t(`Description`)),
      locations: yupObjectArray({ id: yup.string().required(), name: yup.string().required() })
        .required()
        .min(1, 'Select one or multiple locations')
        .label(t(`Locations`)) as any,
      teams: yupObjectArray({ id: yup.string().required(), name: yup.string().required() })
        .required()
        .min(1, 'Select one or multiple teams')
        .label(t(`Teams`)) as any,
      types: yup
        .array(yup.mixed().oneOf(Object.values(WorkItemType)).required())
        .required()
        .min(1, 'Select one or multiple work item types')
        .label(t('Types')),
    }),
  )
  return schema
}

// ResourcePlanningGroupDialogCreate.useSave = () => {
//   const [createRPG, createRPGMutation] = useCreateResourcePlanningGroupMutation()

//   async function save(input: createRPGInput) {
//     const create = await createRPG({
//       variables: {
//         companyId: input.companyId,
//         name: input.name,
//         description: input.description,
//         teamIds: input.teams?.map((t) => t.id.toString()) ?? [],
//         locationIds: input.locations && input.locations.length > 0 ? input.locations.map((l) => l.id.toString()) : [],
//         workItemTypes: input.types && input.types.length > 0 ? (input.types as WorkItemType[]) : [],
//       },
//     })

//     if (!create.data?.createResourcePlanningGroup?.id) {
//       return null
//     }

//     return create.data?.createResourcePlanningGroup
//   }

//   return { save, mutations: [createRPGMutation] }
// }
