import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { useHistory } from '../../shared/use-history'
import { LoadingSwitchPanel } from '../../shared/loading-switch-panel'
import { useParams } from 'react-router-dom'
import { SimpleDialog } from '../../shared/dialog/simple-dialog'
import { useQuery } from '@apollo/client'
import { GET_TRID_HISTORY } from '../../gql/trackings'
import { GridColDef } from '@mui/x-data-grid'
import { SimpleDataGrid } from '../../shared/simple-data-grid'

export const TrackingDetailsDialogRoute = () => {
  const params = useParams()
  return <TrackingDetailsDialog open tracking={params.tridIdAndCode ?? null} />
}

export const TrackingDetailsDialog = ({ tracking, open }: { tracking: any; open: boolean }) => {
  const h = useHistory()
  const companyId = useCurrentCompanyId()

  const {
    error: historyError,
    loading: historyLoading,
    data: history,
  } = useQuery(GET_TRID_HISTORY, {
    variables: {
      trackingCode: tracking.substring(tracking.indexOf('-') + 1),
      company: +companyId!,
    },
  })

  if (historyError) {
    console.error(historyError)
  }

  const handleClose = async () => {
    h.push(`/trackingids`, { replace: true })
  }

  const historyResults = history && history.tridStatusHistory
  const sizeHistoryResults = historyResults && historyResults.lenght

  const columns: GridColDef[] = [
    { field: 'task', headerName: 'Task', width: 140, valueGetter: params => params.row.task },
    { field: 'status', headerName: 'Status', width: 120, valueGetter: params => params.row.status },
    { field: 'statusName', headerName: 'Status Name', width: 140, valueGetter: params => params.row.statusName },
    {
      field: 'created_on',
      headerName: 'Created On',
      width: 200,
      valueGetter: params =>
        params.row.datetime.split('T')[0] + ' | ' + params.row.datetime.split('T')[1].substring(0, 8),
    },
    {
      field: 'resource_code',
      headerName: 'Resource Number',
      width: 175,
      valueGetter: params => params.row.resource?.code || '-1',
    },
    {
      field: 'resource_name',
      headerName: 'Resource Name',
      width: 300,
      valueGetter: params => params.row.resource?.name || 'Automatic Generated',
    },
  ]

  return (
    <SimpleDialog
      title={'Tracking Id status History ' + (tracking as string).split('-', 2)[1]}
      submit={null}
      open={open}
      minWidth={'xl'}
      onClose={handleClose}
    >
      <LoadingSwitchPanel loadingAnyway={historyLoading} query={null}>
        <SimpleDataGrid
          columns={columns}
          data={historyResults}
          rowCount={sizeHistoryResults}
          loading={historyLoading}
        />
      </LoadingSwitchPanel>
    </SimpleDialog>
  )
}
